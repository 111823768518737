
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
body{
    overflow:auto
}

.navbar-dark .navbar-nav .nav-link{
    color: white;
    font-weight: 800;
}
.nav-item{
    padding-right: 40px;
}
body{
    font-family: 'Quicksand', sans-serif;
}
.bg-dark {
    background-color: #0d1427 !important;
}
.buttonOrder{
    min-height: 35  px;
    min-width: 200px;
    border-radius: 8px !important;
    font-size: 18px;
    font-weight: bold;
    padding: 7px 16px;
    color: white;
    border: 1px solid #198754;
    background-color: #076c3d;
    cursor: pointer;
}
.buttonMyCard{
    min-height: 35  px;
    min-width: 200px;
    border-radius: 8px !important;
    font-size: 18px;
    font-weight: bold;
    padding: 7px 16px;
    color: white;
    border: 1px solid #198754;
    background-color: #076c3d;
    cursor: pointer;
}
.buttonNext{
    min-height: 35  px;
    min-width: 200px;
    border-radius: 8px !important;
    font-size: 18px;
    font-weight: bold;
    padding: 7px 16px;
    color: white;
    border: 1px solid #0a58ca;
    background-color: #0a58ca;
    cursor: pointer;
}
.buttonNext:disabled{
    background-color: #0a58ca7d;
    color: #eee;
    border: 1px solid #0a58ca42;

}
.buttonPre{
    border: 1px solid #6c757d;
    background-color: #6c757d;
    min-width: 100px;
}

.buttonAddEth{
    min-height: 53px;
    min-width: 200px;
    border-radius: 10px !important;
    font-size: 20px;
    font-weight: bold;
    padding: 7px 16px;
    color: white;
    border: 1px solid #f16d0f;
    background-color: #f16d0f;
    cursor: pointer;
}

.buttonApprove{
    height: 55px;
    width: 200px;
    border-radius: 15px !important;
    font-size: 20px;
    font-weight: bold;
    color: white;
   
    border: 1px solid #0a58ca;
    background-color: #0a58ca;

}

.buttonOrder:disabled{
    background-color: #3a956b73;
    color: #eee;
    border: 1px solid #28f37073;

}
.buttonOrder label, .buttonApprove label{
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
}
.address-acc-btn{
    border: 1px solid #eee;
    border-radius: 20px;
    cursor: pointer;
}
.box-metamake{
    height: 80px;
    border: 1px solid;
    color: #0d1427;
    border-radius: 20px;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.btn-account{
    height: 65px;
    border: 1px solid #bcbebf;
    color: #0d1427;
    border-radius: 15px;
    font-size: 1.5em;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.active div,.active span{
    color: #0dcaf0 !important;
    font-weight: bold;
}
.btn_1{
    position: absolute;
    bottom: 25px;
    left: 15%;
}
.btn_2{
    position: absolute;
    bottom: 25px;
    right: 15%;
}
.dialog_start{
    min-height: 800px !important;
}
.alert-unlock{
    background-color: #d1dde9d1;
    border-radius: 8px;
    text-align: center;
    padding: 8px 25px;
    /* color: #0d6efd; */
    font-weight: bold;
    border: 1px solid #eee;
    font-size: 1.1em;

}
.input-token-title{
    border: 1px solid #98a2ab;
    height: 70px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-left: 20px;
    border-radius: 10px 0px 0px 10px ; 
    width: 35%;
}
.input-token-value {
    border: 1px solid #98a2ab;
    height: 70px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-radius: 0px 10px 10px 00px ;
    border-left: 0;
    width: 65%;
}

.input-token-button{
    border: 1px solid;
    border-radius: 0px 10px 10px 0px;
    border-left: 0;
}
.box-success{
    border-radius: 10px;
    border: 1px solid #eee;
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
}
.input-danger {
    /* outline: 1px; */
    border: 1px solid red;
}
.box-steps{
     width: 100%;
    /* background: #e8ebed; */
    padding: 15px;
    border-radius: 10px; 
    /* border: 1px solid #938c8c; */
}
.MuiStepLabel-alternativeLabel{
    color: black !important;
}
@media only screen and (max-width: 800px) {
    .dialog_start{
        display: none;
    }
  }
